import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import fetchOrdersAction from "../../../redux/actions/orders/ordersActions";

const MyOrders = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const orderState = useSelector((state) => state.orders.orders);
    const authUser = useSelector((state) => state.auth.authenticated.user);
    const featuresState = useSelector((state) => state.features);

    useEffect(() => {
        dispatch(fetchOrdersAction());
    }, []);
    return <>
        <div >
            {
                orderState.loading
                    ?
                    <div className="row">
                        <div className="col-md-12 justify-content-center">
                            {[...Array(7)].fill("").map((e, i) => (
                                <ContentLoader
                                    speed={2}
                                    width={"100%"}
                                    height={50}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    key={i}
                                    style={{opacity: Number(2 / i).toFixed(1)}}
                                    {...props}
                                >
                                    <rect x="0" y="13" rx="4" ry="4" width="24%" height="17"/>
                                    <rect x="25%" y="13" rx="4" ry="4" width="24%" height="17"/>
                                    <rect x="50%" y="13" rx="4" ry="4" width="24%" height="17"/>
                                    <rect x="75%" y="13" rx="4" ry="4" width="24%" height="17"/>
                                </ContentLoader>
                            ))}
                        </div>
                    </div>
                : orderState.orders && orderState.orders.length === 0 ? (
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="no-page-found-wrapper ">
                            <h3>{t("cart_items_count_zero")}</h3>
                            <div className="mb-3">
                                <i className="fa fa-times"/>
                            </div>
                            <Link to="/shop" className="mt-3">
                                <button className="submit-btn">
                                    <i
                                        className={`fa ${
                                            document.body.dir === "ltr"
                                                ? "fa-chevron-left mr-2"
                                                : "fa-chevron-right ml-2"
                                        }`}
                                    />
                                    {t("continue_shopping")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                orderState.orders &&
                orderState.orders.length > 0 && (
                    <div className="row">
                        <div className="col-md-12 justify-content-center">
                            <div className="confirm-checkout-wrapper">
                                <div className="confirm-checkout-wrapper">
                                    <div className="table-responsive">
                                        <table
                                            className="table-bordered table"
                                            style={{
                                                textAlign: document.body.dir === "rtl" && "start",
                                            }}
                                        >
                                            <thead>
                                            <th>Num</th>
                                            <th>{t("status")}</th>
                                            {/*<th>{t("payment_status")}</th>*/}
                                            <th>{t("date")}</th>
                                            <th>{t("actions")}</th>
                                            </thead>
                                            <tbody>
                                            {orderState.orders.map((order) => (
                                                <tr key={order.id}>
                                                    <td>
                                                        <Link to={"/orders/" + order.id}>
                                                            {order.id}
                                                        </Link>
                                                    </td>
                                                    <td>{t(`${order.status.name}`)}</td>
                                                    {/*<td>{t(`${order.payment_status.name}`)}</td>*/}
                                                    <td>{order.date}</td>
                                                    <td>
                                                        <Link to={"/orders/" + order.id}>
                                                            <i className="fa fa-eye mx-2"></i>
                                                            {t("view")}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    </>
}
export default MyOrders