import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { number_format } from "../shared/shared";
import '../css/profile.css'
import MobileProfile from "./MobileProfile/MobileProfile";

function Profile() {
  const { t, i18n } = useTranslation();
  const authState = useSelector((state) => state.auth.authenticated);
  const storeInfosState = useSelector((state) => state.storeInfos);

  return (<>
          <div className={'mobileOnly'}>
              <MobileProfile>

              </MobileProfile>
          </div>

          {/*<section className="checkout-section">*/}
          {/*    <div className="custom-container">*/}
          {/*        <div className="checkout-wrapper">*/}
          {/*            <h4*/}
          {/*                className="login-form-header"*/}
          {/*                style={{textAlign: document.body.dir === "rtl" && "right"}}*/}
          {/*            >*/}
          {/*                {t("Profile")}*/}
          {/*            </h4>*/}
          {/*            {!authState?.loading ? (*/}
          {/*                <div className="checkout-products-incart mb-3">*/}
          {/*                    <dl className="d-flex">*/}
          {/*                        <dt>{t("Name")}:</dt>*/}
          {/*                        <dd*/}
          {/*                            className={`${*/}
          {/*                                document.body.dir === "ltr" ? "ml-auto" : "mr-auto"*/}
          {/*                            }`}*/}
          {/*                        >*/}
          {/*                            {authState?.user?.fullname}*/}
          {/*                        </dd>*/}
          {/*                    </dl>*/}
          {/*                    <dl className="d-flex">*/}
          {/*                        <dt>{t("Phone")}:</dt>*/}
          {/*                        <dd*/}
          {/*                            className={`${*/}
          {/*                                document.body.dir === "ltr" ? "ml-auto" : "mr-auto"*/}
          {/*                            }`}*/}
          {/*                        >*/}
          {/*                            {authState?.user?.phone}*/}
          {/*                        </dd>*/}
          {/*                    </dl>*/}
          {/*                    {authState?.user?.companyName && (*/}
          {/*                        <dl className="d-flex">*/}
          {/*                            <dt>{t("Status")}:</dt>*/}
          {/*                            <dd*/}
          {/*                                className={`${*/}
          {/*                                    document.body.dir === "ltr" ? "ml-auto" : "mr-auto"*/}
          {/*                                }`}*/}
          {/*                            >*/}
          {/*                                {authState.user.isValide === 1 ? (*/}
          {/*                                    <span className="status-confirmed">{t("Confirmed")}</span>*/}
          {/*                                ) : (*/}
          {/*                                    <span className="status-pending">{t("Pending")}</span>*/}
          {/*                                )}*/}
          {/*                            </dd>*/}
          {/*                        </dl>*/}
          {/*                    )}*/}
          {/*                    {authState?.user?.companyName && (*/}
          {/*                        <dl className="d-flex">*/}
          {/*                            <dt>{t("Company")}:</dt>*/}
          {/*                            <dd*/}
          {/*                                className={`${*/}
          {/*                                    document.body.dir === "ltr" ? "ml-auto" : "mr-auto"*/}
          {/*                                }`}*/}
          {/*                            >*/}
          {/*                                {authState.user.companyName}*/}
          {/*                            </dd>*/}
          {/*                        </dl>*/}
          {/*                    )}*/}
          {/*                    {authState?.user?.salary && authState?.user?.isValide === 1 && (*/}
          {/*                        <dl className="d-flex">*/}
          {/*                            <dt>{t("Salary")}:</dt>*/}
          {/*                            <dd*/}
          {/*                                className={`${*/}
          {/*                                    document.body.dir === "ltr" ? "ml-auto" : "mr-auto"*/}
          {/*                                }`}*/}
          {/*                            >*/}
          {/*                                {storeInfosState?.store_infos?.currency*/}
          {/*                                    ? storeInfosState.store_infos.currency !== "DZD"*/}
          {/*                                        ? t("currency", {*/}
          {/*                                            price: number_format(authState?.user?.salary),*/}
          {/*                                            currency: storeInfosState.store_infos.currency,*/}
          {/*                                        })*/}
          {/*                                        : t("DA", {*/}
          {/*                                            price: number_format(authState?.user?.salary),*/}
          {/*                                        })*/}
          {/*                                    : number_format(authState?.user?.salary)}*/}
          {/*                            </dd>*/}
          {/*                        </dl>*/}
          {/*                    )}*/}
          {/*                </div>*/}
          {/*            ) : (*/}
          {/*                <Skeleton count={4} height={25} className="my-3"/>*/}
          {/*            )}*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</section>*/}
      </>

  );
}

export default React.memo(Profile);
