import {number_format} from "../../shared/shared";
import Skeleton from "react-loading-skeleton";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import MyOrders from "./components/MyOrders";

const MobileProfile = ({children}) => {
    const { t, i18n } = useTranslation();
    const authState = useSelector((state) => state.auth.authenticated);
    const storeInfosState = useSelector((state) => state.storeInfos);
    return <>
        <div id="accordion">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                            <i className={'fa fa-user mr-3 '} aria-hidden="true" style={{fontSize: 20}}></i>
                            <span

                                style={{textAlign: document.body.dir === "rtl" && "right"}}
                            >
                                Personal information
                                {/*{t("Profile")}*/}
                            </span>
                        </button>
                    </h5>
                </div>

                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                        {!authState?.loading ? (
                            <div className="checkout-products-incart mb-3">
                                <dl className="d-flex">
                                    <dt>{t("Name")}:</dt>
                                    <dd
                                        className={`${
                                            document.body.dir === "ltr" ? "ml-auto" : "mr-auto"
                                        }`}
                                    >
                                        {authState?.user?.fullname}
                                    </dd>
                                </dl>
                                <dl className="d-flex">
                                    <dt>{t("Phone")}:</dt>
                                    <dd
                                        className={`${
                                            document.body.dir === "ltr" ? "ml-auto" : "mr-auto"
                                        }`}
                                    >
                                        {authState?.user?.phone}
                                    </dd>
                                </dl>
                                {authState?.user?.companyName && (
                                    <dl className="d-flex">
                                        <dt>{t("Status")}:</dt>
                                        <dd
                                            className={`${
                                                document.body.dir === "ltr" ? "ml-auto" : "mr-auto"
                                            }`}
                                        >
                                            {authState.user.isValide === 1 ? (
                                                <span className="status-confirmed">{t("Confirmed")}</span>
                                            ) : (
                                                <span className="status-pending">{t("Pending")}</span>
                                            )}
                                        </dd>
                                    </dl>
                                )}
                                {authState?.user?.companyName && (
                                    <dl className="d-flex">
                                        <dt>{t("Company")}:</dt>
                                        <dd
                                            className={`${
                                                document.body.dir === "ltr" ? "ml-auto" : "mr-auto"
                                            }`}
                                        >
                                            {authState.user.companyName}
                                        </dd>
                                    </dl>
                                )}
                                {authState?.user?.salary && authState?.user?.isValide === 1 && (
                                    <dl className="d-flex">
                                        <dt>{t("Salary")}:</dt>
                                        <dd
                                            className={`${
                                                document.body.dir === "ltr" ? "ml-auto" : "mr-auto"
                                            }`}
                                        >
                                            {storeInfosState?.store_infos?.currency
                                                ? storeInfosState.store_infos.currency !== "DZD"
                                                    ? t("currency", {
                                                        price: number_format(authState?.user?.salary),
                                                        currency: storeInfosState.store_infos.currency,
                                                    })
                                                    : t("DA", {
                                                        price: number_format(authState?.user?.salary),
                                                    })
                                                : number_format(authState?.user?.salary)}
                                        </dd>
                                    </dl>
                                )}
                            </div>
                        ) : (
                            <Skeleton count={4} height={25} className="my-3"/>
                        )}
                    </div>
                </div>
            </div>
            <div className="card">d
                <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                            <i className="fa fa-gift mr-3" aria-hidden="true" style={{fontSize: 20}}></i>

                            My Orders
                        </button>
                    </h5>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body">
                        <MyOrders></MyOrders>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                            <i className="fa fa-heart mr-3" aria-hidden="true" style={{fontSize: 20}}></i>

                            My wish list
                        </button>
                    </h5>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">

                    </div>
                </div>
            </div>
        </div>

        {/*<div className={'mt-4 d-flex justify-content-between px-2'}>*/}
        {/*    <button type="button" className="btn btn-outline-primary btn-lg">*/}

        {/*        Primary*/}
        {/*    </button>*/}
        {/*    <button type="button" className="btn btn-outline-danger btn-lg">*/}
        {/*        <i className="fa fa-sign-in mr-4" aria-hidden="true" style={{fontSize: 20}}></i>*/}
        {/*        Logout*/}
        {/*    </button>*/}
        {/*</div>*/}
    </>
}
export default MobileProfile