import "./css/App.css";
import "./css/index.css";
import "./css/dropdown.css";
import React, { Suspense, useEffect, lazy, useRef } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchCsrfTokenAction from "./redux/actions/csrfTokenAction";
import { fetchGetCart } from "./redux/actions/cart/getCartActions";
import { fetchUserAction } from "./redux/actions/auth/userActions";
import fetchStoreInfosAction from "./redux/actions/settings/storeInfosActions";
import fetchWishlistAction from "./redux/actions/wishlist/wishlistActions";
import { fetchFeaturedCategoriesAction } from "./redux/actions/categories/featuredCategoriesActions";
import { useTranslation } from "react-i18next";
import fetchFeatures from "./redux/actions/featuresAction";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Profile from "./pages/Profile";
import bootstrapDetectBreakpoint from "./bootstrapBreakpoint";

const HomePage = lazy(() => import("./pages/HomePage"));
const ShopPage = lazy(() => import("./pages/ShopPage"));
const CheckoutPage = lazy(() => import("./pages/CheckoutPage"));
const NotFoundPage = lazy(() => import("./pages/404Page"));
const OrdersPage = lazy(() => import("./pages/OrdersPage"));
const OrderPage = lazy(() => import("./pages/OrderPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const TermsAndPoliciesPage = lazy(() => import("./pages/TermsAndPoliciesPage"));
const SignInPage = lazy(() => import("./pages/SignInPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage"));
const AppLayout = lazy(() => import("./layout/AppLayout"));
const WishlistPage = lazy(() => import("./pages/WishlistPage"));
const ProductScreen = lazy(() => import("./pages/ProductScreen"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));

const App = ({props}) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.authenticated);
  // const breakPoint = useSelector((state) => state.uiReducer.breakPoint);
  const featuresState = useSelector((state) => state.features);
  const storeInfosState = useSelector((state) => state.storeInfos);
  const isMounted = useRef(false);
  const { i18n } = useTranslation();

  useEffect(() => {

    window.addEventListener("resize", (event) => {
      console.log('resize detected')
      dispatch({type: 'SET_BREAK_POINT', payload: bootstrapDetectBreakpoint()})
    })
  }, [props]);
  useEffect(() => {
    dispatch(fetchCsrfTokenAction());
    dispatch(fetchUserAction());
    dispatch(fetchStoreInfosAction());
    dispatch(fetchFeatures());
    dispatch(fetchFeaturedCategoriesAction());
  }, [props]);

  useEffect(() => {
    if (isMounted.current)
      if (storeInfosState?.store_infos?.recaptcha_validation === "0") {
        let recaptcha = document.getElementsByClassName("grecaptcha-badge");
        for (let i = 0; i < recaptcha.length; i++)
          recaptcha[i].style.visibility = "hidden";
      } else isMounted.current = true;
  }, [isMounted.current, storeInfosState?.store_infos?.recaptcha_validation]);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  useEffect(() => {
    if (
        featuresState &&
        featuresState.features &&
        featuresState.features.includes("wishlist")
    )
      dispatch(fetchWishlistAction());
  }, [
    featuresState &&
    featuresState.features &&
    featuresState.features.includes("wishlist"),
  ]);

  useEffect(() => {
    if (
        featuresState &&
        featuresState.features &&
        featuresState.features.includes("add-to-cart")
    )
      dispatch(fetchGetCart());
  }, [
    featuresState &&
    featuresState.features &&
    featuresState.features.includes("add-to-cart"),
  ]);

  return (
      <BrowserRouter>
        <Suspense fallback={<Skeleton width={"100%"} height={"100%"} />}>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<HomePage />} />
              <Route path="shop" element={<ShopPage />} />
              <Route path="categories/:categorySlug" element={<ShopPage />} />
              <Route path="shop/:productSlug" element={<ProductScreen />} />
              {featuresState?.features?.includes("add-to-cart") && (
                  <Route path="checkout" element={<CheckoutPage />} />
              )}

              <Route element={<RequireGuest authState={authState} />}>
                <Route path="login" element={<SignInPage />} />
                <Route path="register" element={<SignUpPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="reset-password" element={<ResetPasswordPage />} />
              </Route>

              <Route element={<RequireAuth authState={authState} />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/orders/:orderId" element={<OrderPage />} />
                {featuresState &&
                    featuresState.features &&
                    featuresState.features.includes("wishlist") && (
                        <Route path="/wishlist" element={<WishlistPage />} />
                    )}
              </Route>

              <Route path="about-us" element={<AboutUsPage />} />
              {featuresState &&
                  featuresState.features &&
                  featuresState.features.includes("contact") && (
                      <Route path="contact-us" element={<ContactUsPage />} />
                  )}
              <Route
                  path="terms-and-policies"
                  element={<TermsAndPoliciesPage />}
              />

              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
};

const RequireAuth = ({ authState }) => {
  const location = useLocation();
  return authState.isLogged ? (
      <Outlet />
  ) : (
      <Navigate to="/login" state={{ from: location }} />
  );
};

const RequireGuest = ({ authState }) => {
  const location = useLocation();
  if (authState.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
};
export default App;