import {
    FACILITY_REQUEST,
    FACILITY_SUCCESS,
    FACILITY_FAIL,
  } from "../../../shared/constants";
  
  const initialState = {
    statusCode: 200,
    message: "",
    errors: null,
    loading: false,
  };
  
  const payFacilityReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case FACILITY_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
        };
      case FACILITY_SUCCESS:
        return {
          ...state,
          message: payload.message,
          statusCode: payload.statusCode,
          errors: null,
          loading: false,
        };
      case FACILITY_FAIL:
        return {
          ...state,
          statusCode: payload.statusCode,
          message: payload.message,
          errors: payload.errors,
          loading: false,
        };
      default:
        return state;
    }
  };
  export default payFacilityReducer;
  