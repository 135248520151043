import {
  CSRF_TOKEN_REQUEST,
  CSRF_TOKEN_SUCCESS,
  CSRF_TOKEN_FAIL,
} from "../../shared/constants";
import { api } from "../../shared/shared";

const fetchCsrfTokenBegin = () => ({
  type: CSRF_TOKEN_REQUEST,
});

const fetchCsrfTokenSuccess = (csrf_token) => ({
  type: CSRF_TOKEN_SUCCESS,
  payload: { csrf_token },
});

const fetchCsrfTokenFailure = (error) => ({
  type: CSRF_TOKEN_FAIL,
  payload: { error },
});

const fetchCsrfTokenAction = () => async (dispatch) => {
  fetchCsrfTokenBegin();
  return api
    .get("/sanctum/csrf-cookie")
    .then((res) => {
      console.log('/////////////////////////////////////////////////////')
      console.log('fetchCsrfTokenAction')
      console.log(res)
      dispatch(fetchCsrfTokenSuccess(true));
    })
    .catch((error) => {
      console.log('/////////////////////////////////////////////////////')
      console.log('fetchCsrfTokenAction error')
      console.log(error)
      dispatch(fetchCsrfTokenFailure(error.message));
    });
};

export default fetchCsrfTokenAction;
