import bootstrapDetectBreakpoint from "../../bootstrapBreakpoint";

const initialState = {
    breakPoint: bootstrapDetectBreakpoint()
};

const uiReducer = (state = initialState, action) => {
    const { type, payload } = action;
    // alert(payload)
    switch (type) {

        case 'SET_BREAK_POINT':
            return {
                ...state,

                breakPoint: payload,
            };
        default:
            return state;
    }
};
export default uiReducer;
