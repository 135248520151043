import {
  ORDERS_FAIL,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchOrdersBegin = () => ({
  type: ORDERS_REQUEST,
});

const fetchOrdersSuccess = (orders) => ({
  type: ORDERS_SUCCESS,
  payload: { orders },
});

const fetchOrdersFailure = (error) => ({
  type: ORDERS_FAIL,
  payload: { error },
});

const fetchOrdersAction = (slug) => (dispatch) => {
  dispatch(fetchOrdersBegin());
  api
    .get("api/orders")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchOrdersSuccess(res.data.data));
      } else {
        dispatch(fetchOrdersFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchOrdersFailure(error.message));
    });
};
export default fetchOrdersAction;
